import {useShared, Link}                 from "@interactivevision/visitor-react";
import React                             from "react";
import {ReactComponent as FacebookIcon}  from "../../../../assets/icons/facebook.svg";
import {ReactComponent as InstagramIcon} from "../../../../assets/icons/instagram.svg";
import {ReactComponent as LinkedIn}      from "../../../../assets/icons/linked.svg";
import FlagUe                            from "../../components/flag-ue";
import FormNewsletter                    from "../../form/form-newsletter";
import {PageTitle}                       from "../../typography/page-title";
import {Paragraph}                       from "../../typography/paragraph";
import styles                            from "./footer.module.scss";
import SectionWrapper                    from "./section-wrapper";
import {$t}                              from "@interactivevision/translations";
import {route}                           from "@interactivevision/routing";


export const Footer = () => {
  // @ts-ignore
  const {menu} = useShared();

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.newsletterContainer}>
          <SectionWrapper>
            <div className={styles.textBox}>
              <div className={styles.titleWrapper}>
                <PageTitle as="h4">{$t("general.talk")} </PageTitle>
              </div>
              <Paragraph weight={600}>{$t("general.newsletter_description")}</Paragraph>
            </div>
            <FormNewsletter/>
          </SectionWrapper>
        </div>
        <div className={styles.footerContainer}>
          <SectionWrapper>
            <div className={styles.contactBox}>
              <div className={styles.contactBoxItem}>
                <a href="mailto:kontakt@mdinwestor.pl" title="Adres email">kontakt@mdinwestor.pl</a>
              </div>
              <div className={styles.contactBoxItem}>
                <a href="tel:730968580" title="Telefon kontaktowy">+48 730 968 580</a>
              </div>
              <div className={styles.contactBoxItem}>
                <div className={styles.socials}>
                  <a href="#" target="_blank" rel="noopener" title="Facebook">
                    <FacebookIcon/>
                  </a>
                  <a href="#" target="_blank" rel="noopener" title="Instagram">
                    <InstagramIcon/>
                  </a>
                  <a href="#" target="_blank" rel="noopener" title="LinkedIn">
                    <LinkedIn/>
                  </a>
                </div>
              </div>
            </div>
          </SectionWrapper>
        </div>
        <div className={styles.footerMenu}>
          <SectionWrapper>
            <ul className={styles.menuList}>
              <li>
                <Link href={route("homepage_module")} title={$t("general.homepage")}>{$t("general.homepage")}</Link>
              </li>
              {
                menu?.menu_top.map((element, index) => (
                  <li key={index}>
                    <Link target={element.target} rel={element.rel} href={element.url} title={element.name}>{element.name}</Link>
                  </li>
                ))
              }
            </ul>
            <div className={styles.footerBottom}>
              <div className={styles.copyrights}>
                <span>© Copyright 2023 MD Inwestor</span>
                <div className={styles.menuBottom}>
                  {
                    menu?.menu_bottom.map((element, index) => (
                      <Link key={index} target={element.target} rel={element.rel} href={element.url} title={element.name}>
                        <span>{element.name}</span>
                      </Link>
                    ))
                  }
                </div>
              </div>
              {/*<div className={styles.realization}>*/}
              {/*  <span>{$t("general.realisation_description")}</span>*/}
              {/*  <a href="#" title={$t("general.realisation_company")} rel="noopener">*/}
              {/*    <IVLogo/>*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </SectionWrapper>
        </div>
      </footer>
      <FlagUe/>
    </>
  );
};

export default Footer;
